import { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { QrReader } from "react-qr-reader";
// import QrReader from "react-qr-scanner";

import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../common/util/constants";
import TopNavigation from "../common/components/TopNavigation";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const useStyles = makeStyles((theme) => ({
  headerPaper: {
    width: "90%",
    margin: "2rem auto",
    padding: "1rem 2rem",
    textAlign: "center",
    borderRadius: "0.66rem",
    background: "#eff0f4",
    boxShadow: theme.shadows[3],
  },
  cameraPaper: {
    width: "90%",
    margin: "2rem auto",
    padding: "1rem 2rem",
    textAlign: "center",
    borderRadius: "0.66rem",
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.headerPaper}>
      <Typography variant="h6" fontWeight={500}>
        Scan Bin Qr Code
      </Typography>
      <Typography variant="body1" sx={{ color: grey[800], mt: 1 }}>
        Scan the bin QR Code and validate it as empted.
      </Typography>
    </Paper>
  );
};

const ViewFinder = () => {
  return (
    <div id="monitor">
      <div
        className="scan"
        style={{ position: "absolute", top: 0, left: 0 }}
      ></div>
      <div className="screen"></div>
    </div>
  );
};

const ScanBinQr = () => {
  const classes = useStyles();

  const [success, setSuccess] = useState(null);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  const token = useSelector((state) => state.session.user.attributes.apitoken);

  const resetState = () => {
    setSuccess(null);
    setLoading(false);
    setError(false);
  };

  const handleResult = async (result, error) => {
    if (!!error) {
      return;
    }
    if (!!result) {
      try {
        setLoading(true);
        const response = await fetch(`${API_URL}/api/bins/status`, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ description: result?.text }),
        });

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message);
        }

        setSuccess(true);
      } catch (error) {
        setError({ message: error.message || "Unknown error" });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box>
      <TopNavigation />
      <Header />
      <Paper className={classes.cameraPaper}>
        {success ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "250px",
              height: "250px",
              margin: "0 auto",
            }}
          >
            <DoneAllIcon
              color="positive"
              sx={{
                width: "200px",
                height: "200px",
                border: "1px solid",
                borderRadius: "100%",
                padding: "0.98rem",
              }}
            />
          </Box>
        ) : error ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "250px",
              height: "250px",
              margin: "0 auto",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: red[500], pb: 1 }}>
                {error.message}
              </Typography>
              <Button variant="contained" color="error" onClick={resetState}>
                Retry
              </Button>
            </Box>
          </Box>
        ) : loading ? (
          <CircularProgress
            size={250}
            thickness={0.8}
            disableShrink
            color="positive"
          />
        ) : (
          <QrReader
            onResult={handleResult}
            scanDelay={500}
            ViewFinder={ViewFinder}
            constraints={{ facingMode: "environment" }}
            videoStyle={{
              top: "-25%",
              scale: "1.5",
            }}
            containerStyle={{
              width: "250px",
              height: "250px",
              margin: "auto",
              borderRadius: "40rem",
              transform: "translateY(0%)",
              overflow: "hidden",
            }}
          />
        )}
      </Paper>
    </Box>
  );
};

export default ScanBinQr;
