import { useState } from "react";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageIcon from "@mui/icons-material/Image";
import { makeStyles } from "@mui/styles";
import { blue, grey } from "@mui/material/colors";
import { Typography } from "@mui/material";
import CircularProgressWithLabel from "./Progress";

const useStyles = makeStyles((theme) => ({
  dropZonContainer: {
    border: "1px dashed rgb(209,213,219)",
    height: "16rem",
    margin: "1rem",
    borderRadius: "0.33rem",
    padding: "0.45rem",
  },
  dropZone: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  label: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    borderRadius: "0.28rem",
    background: grey[50],
    padding: "0.88rem",
    "&:hover": { background: grey[100] },
  },
  description: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1.25",
    paddingBottom: "1.5rem",
  },
  cloudIcon: {
    color: "rgb(113,113,112)",
  },
  fileInfo: {
    // maxWidth: "20rem",
    background: "white",
    display: "flex",
    alignItems: "center",
    borderRadius: "0.356rem",
    overflow: "hidden",
    outline: "1px solid",
    outlineColor: grey[300],
  },
  imgIconContainer: {
    padding: "0.5rem 0.75rem",
    height: "100%",
    display: "grid",
    placeItems: "center",
    borderRight: "1px solid",
    borderRightColor: grey[300],
  },
  imgIcon: {
    color: blue[500],
  },
  fileName: {
    padding: "0.75rem 0.5rem",
    height: "100%",
    fontSize: "0.94rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  progress: { margin: "auto" },
}));

const Uploader = ({ title, fileName }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const classes = useStyles();

  const startSimulatedProgress = () => {
    setUploadProgress(0);

    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 95) {
          clearInterval(interval);
          return prevProgress;
        }
        return prevProgress + 5;
      });
    }, 500);
    return interval;
  };

  return (
    <Dropzone
      multiple={false}
      onDrop={async (acceptedFile) => {
        setIsUploading(true);

        const progressInterval = startSimulatedProgress();

        // Handle file uploading

        if(acceptedFile[0].type.startsWith("image")) {
          const data = new FormData()
          data.append(fileName, acceptedFile[0])

          try {
            const response = await fetch('/avatars', {
              method: 'POST',
              body: data
            });
  
            if (!response.ok) {
              throw new Error("Internal Error Please Try Again Later")
            }
            setUploadProgress(100);
          } catch (error) {
            alert(error?.message || "Can't upload your image please try again later");
          } finally {
            clearInterval(progressInterval);
          }

        }

        
      }}
    >
      {({ getRootProps, getInputProps, acceptedFiles }) => (
        <div {...getRootProps()} className={classes.dropZonContainer}>
          <div className={classes.dropZone}>
            <label htmlFor="dropzone-file" className={classes.label}>
              <div className={classes.description}>
                <CloudUploadIcon className={classes.cloudIcon} />
                <p className={classes.cloudIcon}>
                  <Typography component="span" fontWeight={500}>
                    Click to upload 
                  </Typography>
                  or drag and drop
                </p>
                <p className={classes.cloudIcon} style={{ fontSize: "0.9rem" }}>
                  {title}
                </p>
              </div>
              {/* render this element when the file is dropped */}
              {acceptedFiles && acceptedFiles[0] &&  acceptedFiles[0].type.startsWith("image") ? (
                <div className={classes.fileInfo}>
                  <div className={classes.imgIconContainer}>
                    <ImageIcon className={classes.imgIcon} />
                  </div>
                  <div className={classes.fileName}>
                    {acceptedFiles[0].name}
                  </div>
                </div>
              ) : null}
              {isUploading ? (
                <div className={classes.progress}>
                  <CircularProgressWithLabel value={uploadProgress} />
                </div>
              ) : null}
            </label>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default Uploader;
