import { useState } from "react";
import { Box, Button, Icon, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TopNavigation from "../common/components/TopNavigation";
import TrashNegative from "../resources/images/icon/trashNegative.svg";
import TrashPositive from "../resources/images/icon/trashPositive.svg";
import Uploader from "../common/components/Uploader";
import { useParams } from "react-router-dom";
import { useEffectAsync } from "../reactHelper";
import { API_URL } from "../common/util/constants";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    margin: "2rem auto",
    width: "fit-content",
    background: "#fe5c5021",
    borderRadius: "4rem",
    boxShadow: theme.shadows[4],
    aspectRatio: "1/1",
  },
  imageIcon: {
    height: "100%",
  },
  iconRoot: {
    textAlign: "center",
    fontSize: "3.5rem",
    margin: "1rem 1.2rem",
  },
  info: {
    margin: "auto",
    marginTop: "4rem",
    maxWidth: theme.dimensions.drawerWidthDesktop,
  },
  action: {
    width: "fit-content",
    margin: "2rem auto",
  },
  button: {
    width: theme.dimensions.drawerWidthDesktop,
    color: "white",
  },
}));

const TextRow = ({ label, text }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: "1rem",
        alignItems: "center",
        marginTop: "0.34rem",
      }}
    >
      <Typography variant="subtitle2" sx={{ flexGrow: 1, textAlign: "center" }}>
        {label}
      </Typography>
      <Typography variant="body2" sx={{ flexGrow: 1, textAlign: "center" }}>
        {text}
      </Typography>
    </Box>
  );
};

const BinPage = () => {
  const classes = useStyles();

  const { description } = useParams();

  const [bin, setBin] = useState({
    empted: false,
    status: "Unempted",
    lastOperation: "10/02/2023-14:43",
  });

  useEffectAsync(async () => {
    try {
      const response = await fetch(`${API_URL}/api/bin?id=${description}`);

      if (response.ok) {
        setBin(await response.json());
      }
    } catch (e) {}
  }, []);

  const handleClick = async () => {
    try {
      const response = await fetch(`${API_URL}/api/bins/empty`, {
        method: "PATCH",
        body: JSON.stringify({ id: bin.id }),
      });

      if (response.status === 202) {
        setBin(await response.json());
      }
    } catch (error) {
      alert("There was an error try again later");
    }
  };

  return (
    <Box>
      <TopNavigation title={description} status={bin?.empted} />
      <Box>
        {bin ? (
          <Box className={classes.iconContainer}>
            <Icon className={classes.iconRoot}>
              <img
                className={classes.imageIcon}
                src={!bin.empted ? TrashNegative : TrashPositive}
              />
            </Icon>
          </Box>
        ) : (
          <Box sx={{ margin: "2rem auto", width: "fit-content" }}>
            <Skeleton variant="circular">
              <img src={TrashPositive} width="80px" height="80px" />
            </Skeleton>
          </Box>
        )}
        {bin ? (
          <Box className={classes.info}>
            <TextRow label="Status" text={bin.status} />
            <TextRow label="Last operation" text={bin.lastOperation} />
          </Box>
        ) : (
          <Box className={classes.info}>
            <Skeleton />
            <Skeleton />
          </Box>
        )}
        <Box className={classes.action}>
          <Button
            variant="contained"
            className={classes.button}
            color="positive"
            disabled={bin?.empted || true}
            onClick={handleClick}
          >
            Empty
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {bin ? (
          <>
            <Uploader title="Before photo (up to 16 MB)" />
            <Uploader title="After photo (up to 16 MB)" />
          </>
        ) : (
          <Skeleton>
            <Uploader title="Before photo (up to 16 MB)" />
            <Uploader title="After photo (up to 16 MB)" />
          </Skeleton>
        )}
      </Box>
    </Box>
  );
};

export default BinPage;
