import React from "react";

import ProgressBar from "@ramonak/react-progress-bar";
import useBinsStatusStyle from "./common/useBinsStatusStyle";

const Example = ({ rate }) => {
  const classes = useBinsStatusStyle();
  return (
    <ProgressBar
      completed={parseFloat(rate)}
      bgColor="#00C49F"
      animateOnRender
      height="36.8px"
      barContainerClassName={classes.barProgContainer}
      customLabel={`${rate} empted`}
      labelClassName={`${classes.typography} ${classes.barProgLabel}`}
    />
  );
};
export default Example;
