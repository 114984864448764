import { useId, useEffect, useCallback } from "react";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import { map } from "./core/MapView";
import { useAttributePreference } from "../common/util/preferences";
import { findFonts } from "./core/mapUtil";

const MapMarkers = ({ markers, showTitles, onClick }) => {
  const id = useId();

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const iconScale = useAttributePreference("iconScale", desktop ? 0.75 : 1);

  const onMarkerClick = useCallback(
    (event) => {
      event.preventDefault();
      const feature = event.features[0];
      if (onClick) {
        onClick(feature.properties.bin);
      }
    },
    [onClick]
  );

  useEffect(() => {
    map.addSource(id, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    if (showTitles) {
      map.addLayer({
        id,
        type: "symbol",
        source: id,
        filter: ["!has", "point_count"],
        layout: {
          "icon-image": "{image}",
          "icon-size": iconScale,
          "icon-allow-overlap": true,
          "text-field": "{title}",
          "text-allow-overlap": true,
          "text-anchor": "bottom",
          "text-offset": [0, -2 * iconScale],
          "text-font": findFonts(map),
          "text-size": 12,
        },
        paint: {
          "text-halo-color": "white",
          "text-halo-width": 1,
        },
      });
      map.on("click", id, onMarkerClick);
    } else {
      map.addLayer({
        id,
        type: "symbol",
        source: id,
        layout: {
          "icon-image": "{image}",
          "icon-size": iconScale,
          "icon-allow-overlap": true,
        },
      });
      map.on("click", id, onMarkerClick);
    }

    return () => {
      if (map.getLayer(id)) {
        map.removeLayer(id);
        map.off("click", id, onMarkerClick);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
        map.off("click", id, onMarkerClick);
      }
    };
  }, [showTitles]);

  useEffect(() => {
    map.getSource(id)?.setData({
      type: "FeatureCollection",
      features: markers.map((bin) => {
        const { latitude, longitude, image, title } = bin;
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [longitude, latitude],
          },
          properties: {
            image: image || "default-neutral",
            title: title || "",
            bin,
          },
        };
      }),
    });
  }, [showTitles, markers]);

  return null;
};

export default MapMarkers;
