import { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DirectionsIcon from "@mui/icons-material/Directions";
import fetcher from "../common/util/fetcher";
import { API_URL } from "../common/util/constants";
import { useDispatch, useSelector } from "react-redux";
import { mapsActions } from "../store";

const useStyles = makeStyles((theme) => ({
  selectBoxContainer: {
    top: "20px",
    left: "1%",
    right: "70%",
    position: "absolute",
    display: "flex",
    gap: "0.5rem",
    background: "white",
    padding: "0.3rem 1rem",
    borderRadius: "0.5rem",
    zIndex: 1,
    opacity: 0.7,
    boxShadow: "0 0 2px rgb(0 0 0/20%), 0 -1px 0 rgb(0 0 0/2%)",
    transition: "all 300ms ease-in",
    [theme.breakpoints.down("md")]: {
      right: "8%",
    },
    "&:hover": {
      opacity: 1,
    },
  },
  selectBox: {
    width: "89%",
  },
  select: {},
  button: {
    aspectRatio: "1/1",
  },
}));

const RouteList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  // const [selectedRoute, setSelectedRoute] = useState({ id: "" });
  const routes = useSelector((state) => state.maps.routes);
  const selectedRoute = useSelector((state) => state.maps.selectedRoute);
  const setSelectedRoute = (payload) =>
    dispatch(mapsActions.setSelectedRoute(payload));
  const setSteps = (payload) => dispatch(mapsActions.setSteps(payload));
  const setItems = (payload) => dispatch(mapsActions.setItems(payload));
  const setLoading = (bool) => dispatch(mapsActions.setLoading(bool));
  const token = useSelector((state) => state.session.user.attributes.apitoken);

  const fetchBins = async () => {
    if (!selectedRoute.id) return;
    try {
      setLoading(true);
      const response = await fetcher(
        API_URL + `/api/bins?routeid=${selectedRoute.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();
      if (!data.length) {
        alert("There is no bin in this route");
        return;
      }
      setItems(data);
      setSteps(
        data
          .filter(({ empted }) => !empted)
          .map((item) => [item.longitude, item.latitude])
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedRoute !== "") {
      const updateRoutes = setInterval(() => {
        fetchBins();
      }, 30000);
      return () => clearInterval(updateRoutes);
    }
  }, [selectedRoute]);

  return (
    <Box className={classes.selectBoxContainer}>
      <FormControl className={classes.selectBox}>
        <InputLabel>Routes</InputLabel>
        <Select
          label="Routes"
          variant="standard"
          className={classes.select}
          value={selectedRoute.id}
          onChange={(e) =>
            setSelectedRoute(
              routes.filter((route) => route.id === e.target.value)[0]
            )
          }
        >
          {routes.map((route) => (
            <MenuItem selected value={route.id} key={route.id}>
              {route.rout_code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton size="large" onClick={fetchBins} className={classes.button}>
        <DirectionsIcon sx={{ color: "#1a73e8" }} />
      </IconButton>
    </Box>
  );
};

export default RouteList;
