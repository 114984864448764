import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  // The bar that contains info
  binInfoBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#0288d1f0",
    padding: "0.4rem 0.5rem",
    borderRadius: "4px",
    color: "#F0F0F0",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    marginBottom: "0.53rem",
  },
  typography: {
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: 1.43,
    letterSpacing: "0.01071em",
  },
  iconText: {
    display: "flex",
    alignItems: "center",
    gap: "0.34rem",
  },
  // The bar that contains progress
  barProgContainer: {
    background: "#f44336",
    borderRadius: "4px",
  },

  barProgLabel: {
    color: "white",
    paddingRight: "10px",
  },
}));
