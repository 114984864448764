import React from "react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MainMap from "./MainMap";
import RouteList from "./RouteList";
import BinsStatus from "../common/components/BinsStatus";
import StatusCard from "../common/components/StatusCard";
import { useDispatch, useSelector } from "react-redux";
import { mapsActions } from "../store";
import BottomMenu from "../common/components/BottomMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",

    position: "fixed",
    left: 0,
    bottom: 0,
    // height: `40px`,
    // width: theme.dimensions.drawerWidthDesktop,
    margin: theme.spacing(1.5),
    zIndex: 3,

    [theme.breakpoints.down("md")]: {
      // width: `calc(100% )`,
    },
  },
  footer: {
    zIndex: 5,
  },
}));

const MainPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const selectedBin = useSelector((state) => state.maps.selectedBin);

  return (
    <div className={classes.root}>
      <RouteList />
      <MainMap />
      <BinsStatus />
      <div className={classes.sidebar}>
        <div className={classes.footer}>
          <BottomMenu />
        </div>
      </div>
      {selectedBin && (
        <StatusCard
          onClose={() => {
            dispatch(mapsActions.setSelectedBin(null));
          }}
        />
      )}
    </div>
  );
};

export default MainPage;
