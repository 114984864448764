import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "maps",
  initialState: {
    // Bins item
    items: [],
    routes: [],
    steps: [],
    // Set Map Camera Position
    currentPosition: [],
    // Next step is the next pos[lng, lat] after current is used for adapting the camera of the map
    nextStep: [],
    loading: false,
    renderButtonCount: 0,
    //For empted bins showing
    showEmpted: false,
    showStatus: false,
    selectedBin: null,
    selectedRoute: { id: "" },
  },
  reducers: {
    setItems(state, action) {
      state.items = action.payload;
    },
    setCurrentPosition(state, action) {
      state.nextStep = state.currentPosition || [];
      state.currentPosition = action.payload;
    },
    setNextStep(state, action) {
      state.nextStep = action.payload;
    },
    setSteps(state, action) {
      state.steps = action.payload;
    },
    setRoutes(state, action) {
      state.routes = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    updateRenderButtonCount(state) {
      state.renderButtonCount += 1;
    },
    toggleShowEmpted(state) {
      state.showEmpted = !state.showEmpted;
    },
    toggleShowStatus(state) {
      state.showStatus = !state.showStatus;
    },
    setSelectedBin(state, action) {
      state.selectedBin = action.payload;
    },
    setSelectedRoute(state, action) {
      state.selectedRoute = action.payload;
    },
  },
});

export { actions as mapsActions };
export { reducer as mapsReducer };
