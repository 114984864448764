import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { map } from "../core/MapView";
import { mapsActions } from "../../store";
import { MapButton } from "../core/button";

const ICONS = {
  bin: `<svg class="mapboxgl-ctrl-geocoder--icon mapboxgl-ctrl-geocoder--icon-search maplibregl-ctrl-geocoder--icon maplibregl-ctrl-geocoder--icon-search" viewBox="0 0 24 24" xml:space="preserve" width="18" height="18" data-testid="DeleteIcon"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path></svg>`,
  status:
    '<svg focusable="false" aria-hidden="true" viewBox="0 0 24 24"><path d="M4 9h4v11H4zm12 4h4v7h-4zm-6-9h4v16h-4z"></path></svg>',
};
const MapButtons = () => {
  const dispatch = useDispatch();

  const renderButtonCount = useSelector(
    (state) => state.maps.renderButtonCount
  );

  const createButton = () => {
    // Toggle bins view
    const toggleBin = new MapButton(
      ICONS.bin,
      () => dispatch(mapsActions.toggleShowEmpted()),
      "toggleBin-37353-ca"
    );
    map.removeControl(toggleBin);
    map.addControl(toggleBin, "top-right");
    // Toggle bins view
    const binStatus = new MapButton(
      ICONS.status,
      () => dispatch(mapsActions.toggleShowStatus()),
      "binStatus-37353-ca"
    );
    map.removeControl(binStatus);
    map.addControl(binStatus, "top-right");
  };

  useEffect(() => {
    if (renderButtonCount < 1) {
      createButton();
      dispatch(mapsActions.updateRenderButtonCount());
    }
  }, []);
  return null;
};
export default MapButtons;
