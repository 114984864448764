import { useEffect } from "react";
import { map } from "../core/MapView";
import { useSelector, useDispatch } from "react-redux";
import { errorsActions, mapsActions } from "../../store";
import { chunkArray } from "../../common/util/converter";
import { solveRouteApi, combineRoutes } from "../core/mapUtil";

const getNextStep = (geodata) => {
  return geodata.directions[0].features[0].geometry.paths[0][1];
};

const Directions = () => {
  const dispatch = useDispatch();
  const { steps, currentPosition } = useSelector((state) => state.maps);
  const setLoading = (bool) => dispatch(mapsActions.setLoading(bool));

  useEffect(() => {
    if (!currentPosition.length || !steps.length) return;

    //Route Layer Function here
    map.addSource("route", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer({
      id: "route-line",
      type: "line",
      source: "route",

      paint: {
        "line-color": "hsl(205, 100%, 50%)",
        "line-width": 4,
        "line-opacity": 0.6,
      },
    });

    // Solve the route

    const solveRoute = async (stops) => {
      if (stops.length > 150) {
        const chunkedStops = chunkArray(stops, 149);
        // map every stops to api call
        const mappedApi = chunkedStops.map(solveRouteApi);

        try {
          setLoading(true);

          const responses = await Promise.all(mappedApi);

          const geoData = combineRoutes(responses);

          map.getSource("route").setData(geoData.routes.geoJson);

          const nextStep = getNextStep(geoData);

          dispatch(mapsActions.setNextStep(nextStep || []));
        } catch (error) {
          dispatch(errorsActions.push("There was a problem"));
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);

          const response = await solveRouteApi(stops);

          let nextStep = getNextStep(response);

          dispatch(mapsActions.setNextStep(nextStep || []));

          map.getSource("route").setData(response.routes.geoJson);
        } catch (error) {
          dispatch(errorsActions.push("There was a problem"));
        } finally {
          setLoading(false);
        }
      }
    };

    const stops = [currentPosition, ...steps];

    solveRoute(stops);

    return () => {
      map.removeLayer("route-line");
      map.removeSource("route");
    };
  }, [steps]);
  return null;
};

export default Directions;
