import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import MapView from "../map/core/MapView";
import MapCurrentLocation from "../map/MapCurrentLocation";
import MapPadding from "../map/MapPadding";
import MapDefaultCamera from "../map/main/MapDefaultCamera";
import MapOverlay from "../map/overlay/MapOverlay";
import Directions from "../map/directions/Directions";
import MapMarkers from "../map/MapMarkers";
import MapButtons from "../map/buttons/MapButtons";
import { mapsActions } from "../store";

const MainMap = () => {
  const theme = useTheme();

  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const showEmpted = useSelector((state) => state.maps.showEmpted);

  const items = useSelector((state) => state.maps.items);

  const dispatch = useDispatch();
  return (
    <>
      <MapView>
        <MapButtons />
        <MapOverlay />
        <MapDefaultCamera />
        <Directions />
        {/* For unempted bin */}
        <MapMarkers
          onClick={(bin) => {
            const parseData = JSON.parse(bin);

            dispatch(mapsActions.setSelectedBin(parseData));
          }}
          markers={items
            .filter(({ empted }) => empted === false)
            .map((item) => ({
              ...item,
              id: item.id_bin,
              image: "redBin-transparent",
            }))}
        />

        {/* For empted bin */}
        {showEmpted && (
          <MapMarkers
            onClick={(bin) => {
              const parseData = JSON.parse(bin);

              dispatch(mapsActions.setSelectedBin(parseData));
            }}
            markers={items
              .filter(({ empted }) => empted)
              .map((item) => ({
                ...item,
                id: item.id_bin,
                image: "greenBin-transparent",
              }))}
          />
        )}
      </MapView>
      <MapCurrentLocation />
      {desktop && (
        <MapPadding left={parseInt(theme.dimensions.drawerWidthDesktop, 10)} />
      )}
    </>
  );
};

export default MainMap;
