import { useDispatch, useSelector, connect } from "react-redux";

import { errorsActions, mapsActions } from "./store";
import { useEffectAsync } from "./reactHelper";
import { API_URL, TOKEN } from "./common/util/constants";

const CachingController = () => {
  const authenticated = useSelector((state) => !!state.session.user);
  const userRoutes = useSelector(
    (state) => state.session.user.attributes.routes
  );

  const dispatch = useDispatch();

  useEffectAsync(async () => {
    if (authenticated) {
      if (!userRoutes?.length) {
        dispatch(errorsActions.push("You're not linked to any route"));
        return;
      }
      const response = await fetch(`${API_URL}/api/routes`, {
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      if (response.ok) {
        const routes = (await response.json()).filter((route) =>
          userRoutes.includes(route.id)
        );
        dispatch(mapsActions.setRoutes(routes));
      } else {
        throw Error(await response.text());
      }
    }
  }, [authenticated]);

  return null;
};

export default connect()(CachingController);
