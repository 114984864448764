import { Box, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { green } from "@mui/material/colors";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      alignItems: "center",
    },
    link: {
      display: "flex",
      flexDirection: "column",
      padding: "0.45rem 1.25rem",
      alignItems: "center",
      justifyContent: "center",
      // color: theme.palette.colors.blackText,
      color: "white",
      textDecoration: "none",
      alignSelf: "stretch",
      fontWeight: 900,
      "&:hover": {
        background: theme.palette.action.hover,
      },
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      textAlign: "center",
      flexGrow: 0.8888,
      color: "white",
    },
  };
});

const TopNavigation = ({ title, status }) => {
  const classes = useStyles();

  return (
    <Paper
      square
      elevation={4}
      className={classes.container}
      sx={{ background: status ? green[500] : "#fe5c50" }}
    >
      <Link to="/" className={classes.link}>
        <Box className={classes.iconContainer}>
          <ArrowBackIcon sx={{ width: "24px", height: "24px" }} />
        </Box>
        <Typography component="p">Back</Typography>
      </Link>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
    </Paper>
  );
};

export default TopNavigation;
